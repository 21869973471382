<template>
  <footer class="bg-white" aria-labelledby="footer-heading">
    <iframe src="/bigScreen/index.html" width="100%" height="100%"></iframe>
<!--    <img-->
<!--      src="@/assets/home-bg.png"-->
<!--      style="width: 80%; margin: 20px auto; padding-top: 120px"-->
<!--    />-->
  </footer>
</template>
<script>
export default {
  name: "AuthCoverLayout",
  /**
   * 调用组建
   * @type {Object}
   */
  components: {},
  /**
   * 组建属性
   * @type {Object}
   */
  props: {},
  /**
   * 数据字段
   * @return {[type]} [description]
   */
  data() {
    return {};
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面创建时
   * @return {[type]} [description]
   */
  created() {

  },
  /**
   * 页面加载时
   * @return {[type]} [description]
   */
  mounted() {
    this.$cloud.userInfo();
  },
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {},
};
</script>
<style scoped></style>
